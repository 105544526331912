import React from "react";
import PropTypes from "prop-types";
import CrewCard from "./CrewCard";
import {jobs} from "../../data/rarityData.json";
import {GetCrewSupply} from "../../hooks/crewSupply";

let traits = {};

//-----------------------------------------------------------------------------------------------------------
// Functions
//-----------------------------------------------------------------------------------------------------------

function normalise(val, min, max) {
    return (val - min) / (max - min);
}
  
function zeroPad(nr,base){
    var  len = (String(base).length - String(nr).length)+1;
    return Number(len > 0? new Array(len).join('0')+nr : nr);
}

function evaluateCrew( crewMember ){
    const crewCount = GetCrewSupply(); //rarity.count;
    
    //Shuffle traits into object
    crewMember.traits.forEach( trait => {
        //console.log( trait.trait_type, trait.value, trait.trait_count );
        traits[trait.trait_type] = { value: trait.value, count: trait.trait_count };
    })

    // Job based score
    const rarityCollection = jobs['Collection'][traits['Collection'].value];
    const rarityClass = jobs['Class'][traits['Class'].value];
    const rarityTitle = jobs['Title'][traits['Title'].value].boost;

    // Job result
    const rarityWorkMax = 7.7 * 6.5 * 71.8; //rarityCollection * rarityClass * rarityTitle
    const rarityWork = rarityCollection * rarityClass * rarityTitle;
    const workScore = Math.pow( normalise(rarityWork, 1, rarityWorkMax),.2) * 100;

    // Appearance based score
    //const raritySex = rarity.sex[ enums.sex[ crew[0]["sex"] ] ] / crewCount;
    //const rarityBody = rarity.body[ enums.body[ crew[0]["body"] ] ] / crewCount;

    const rarityOutfit = 1 - (traits['Outfit'].count / crewCount);
    const rarityHair = 1 - (traits['Hair Style'].count / crewCount);
    const rarityFacial = 1 - (traits['Facial Feature'].count / crewCount);
    const rarityColor = 1 - (traits['Hair Color'].count / crewCount);
    const rarityHead = 1 - (traits['Headwear'].count / crewCount);
    const rarityBonus = 1 - (traits['Bonus Item'] ? traits['Bonus Item'].count / crewCount : 0 );

    // console.log( "Outfit: ", rarityOutfit);
    // console.log( "Hair: ", rarityHair);
    // console.log( "Head: ", rarityHead);
    // console.log( "Facial: ", rarityFacial);
    // console.log( "Color: ", rarityColor);
    // console.log( "Bonus: ", rarityBonus);

    // Job result
    const rarityLook = rarityOutfit + rarityHair + rarityFacial + rarityColor + rarityHead + rarityBonus;
    console.log( "Look Rarity: ", rarityLook );
    //const lookScore = (1 - (rarityLook - rarity.min)/(rarity.max-rarity.min))*100;
    const lookScore = normalise(rarityLook, 5, 6)*100;
    console.log( "Look Score: ", lookScore );

    // Rarity result
    const rarityScore = (workScore + lookScore)/2;

    return [ lookScore, workScore, rarityScore ];
}

export default function CrewRating( {
    crewMember,
    ...rest
    }) {

    let newName = '';

    //console.log(rarityCollection);
    //console.log(rarityScore);

    if (crewMember.name === 'Crew Member') {
            newName = 'Crew #' + crewMember.token_id;
        } else {
            newName = ''
        }

    const [ lookScore, workScore, rarityScore ] = evaluateCrew(crewMember);

    console.log(lookScore);

    return (
        <CrewCard
          name={newName}
          image={crewMember.image_url}
          rating={zeroPad(Math.round(rarityScore),10)}
          starsLook={zeroPad(Math.round(lookScore),10)}
          starsWork={zeroPad(Math.round(workScore),10)}
        />
    );
}

CrewRating.propTypes = {
    crewMember: PropTypes.object
};
  
CrewRating.defaultProps = {
    crewMember: {
        name: "Crew Member"
    },
};