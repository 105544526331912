import React from "react";

import Hero from "../components/sections/Hero";
import LandingLayout from "../components/layouts/LandingLayout";

export default function Rarity() {
  return (
    <LandingLayout>
      <Hero
        title="Voight-Kampff Testing"
        subtitle="Browse our crew records and analyse the results from our modified Voight-Kampff test.  Compare employment contract rates to ensure an upper hand in price negoiations."
        image="/img/tyapp_img-rarity.svg"
        ctaText="Coming Soon!"
        ctaLink="/rarity"
      />
    </LandingLayout>
  );
}