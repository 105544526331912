import { extendTheme } from "@chakra-ui/react";

const colors = {
    primary: {
    100: "#111",
    200: "#222",
    300: "#333",
    400: "#444",
    500: "#555",
    600: "#666",
    700: "#777",
    800: "#888",
    900: "#999"
  }
};

const customTheme = extendTheme({ 
  config: {
    initialColorMode: "dark",
    useSystemColorMode: false,
  },
  fonts: {
    heading: "abel",
    body: "abel",
  },
  colors,
  styles: {
    global: {
      // styles for the `body`
      body: {
        bg: "#555",
        color: "white",
      }
    }
  }
  
  
});

export default customTheme;
