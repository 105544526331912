import { ethers } from "ethers";
import { useContractCall } from "@usedapp/core";
import crewContractAbi from "../contracts/crewContractABI.json";
import { crewContractAddress } from "../contracts/crewContractAddress";

const crewContractInterface = new ethers.utils.Interface(crewContractAbi);

export function GetCrewOwned(address) {
    const [balance] = useContractCall({
        abi: crewContractInterface,
        address: crewContractAddress,
        method: "balanceOf",
        args: [address],
    }) ?? [];

    if (balance) {
        return balance.toNumber();
    }

    return '?';
}