import React from "react";
import { useQuery } from "react-query";
import { SimpleGrid, Heading, Flex } from "@chakra-ui/react";
import { useEthers } from "@usedapp/core";

import { crewContractAddress } from "../../contracts/crewContractAddress";

import CrewRating from "../../components/elements/CrewRating";
import LandingLayout from "../../components/layouts/LandingLayout";

export default function MyCrew() {
    let ownedCrew = [];

    const { account } = useEthers();

    const { isLoading, data } = useQuery(account && crewContractAddress && ['GET', account, crewContractAddress], () => 
            fetch('https://api.opensea.io/api/v1/assets?owner=' + account + '&asset_contract_address=' + crewContractAddress + '&order_direction=desc&offset=0&limit=50')
            .then((res) => res.json())
            .catch(err => console.error("Bummer!", err))
        )

    //----------------------------------------------------------------------------------------
    // Export local crew JSON only
    
    // const handleSaveToPC = ownedCrew => {
    //     const fileData = ownedCrew//JSON.stringify(ownedCrew);
    //     const blob = new Blob([fileData], {type: "application/json"});
    //     const url = URL.createObjectURL(blob);
    //     const link = document.createElement('a');
    //     link.download = 'ownedCrew.json';
    //     link.href = url;
    //     link.click();
    // }

    // //Exports crew
    // useEffect(() => {

    //     const handleSaveToPC = ownedCrew => {
    //         const fileData = ownedCrew//JSON.stringify(ownedCrew);
    //         const blob = new Blob([fileData], {type: "application/json"});
    //         const url = URL.createObjectURL(blob);
    //         const link = document.createElement('a');
    //         link.download = 'ownedCrew.json';
    //         link.href = url;
    //         link.click();
    //     }

    //     const fileData = JSON.stringify(ownedCrew);
    //     handleSaveToPC(fileData);

    // }, []);

    if (!isLoading && account && data){
        data.assets.forEach(eachCrew => {
            ownedCrew.push( eachCrew );
        })

        // console.log("Name :", ownedCrew[1].name);
        // console.log("Desc :", ownedCrew[1].description);
    }

    return (
        <LandingLayout>
            <Flex
            align="center"
            justify="left"
            direction={{ base: "column", md: "column" }}
            wrap="no-wrap"
            minH="70vh"
            px={8}
            mb={16}
            >
                <Heading
                    as="h1"
                    size="xl"
                    fontWeight="bold"
                    color="primary.900"
                    textAlign={["left", "left", "left", "left"]}
                    mb="10"
                >
                    Contracted Crew Members
                </Heading>

                <SimpleGrid columns={{base: 2, md: 5 }} spacingX={{base: 8, md: 8 }} spacingY={{base: 200, md: 240 }}>
                    {ownedCrew.map( (crewMember) =>
                        <CrewRating key={crewMember.token_id}
                            crewMember={crewMember}
                        />
                    )}
                </SimpleGrid>
            </Flex>
        </LandingLayout>
    );
}