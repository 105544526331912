import { ethers } from "ethers";
import { useContractCall } from "@usedapp/core";
import crewContractAbi from "../contracts/crewContractABI.json";
import { crewContractAddress } from "../contracts/crewContractAddress";

const crewContractInterface = new ethers.utils.Interface(crewContractAbi);

export function GetCrewSupply() {
    const [supply] = useContractCall({
        abi: crewContractInterface,
        address: crewContractAddress,
        method: "totalSupply",
        args: [],
    }) ?? [];

    if (supply) {
        return supply.toNumber();
    }

    return '?';
}