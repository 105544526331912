import React from "react";
import "../styles.css"
import {
  Box,
  Flex,
  Image,
  Heading,
  Stack,
  Text,
  SimpleGrid
} from "@chakra-ui/react";

import {jobs} from "../data/rarityData.json";

import LandingLayout from "../components/layouts/LandingLayout";
import CareerCard from "../components/elements/CareerCard";

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

const title = "Career Composition";

let departments = [];
for (let key in jobs.department) {
    departments.push(key);
    //console.log(key, jobs.department[key]);
}

export default function Career() {
  return (
    <LandingLayout>
        <Flex
            align="center"
            justify={{ base: "center", md: "center", xl: "space-between" }}
            direction={{ base: "column-reverse", md: "row" }}
            alignItems={{ base: "center", md: "center"}}
            wrap="no-wrap"
            minH="20vh"
            mx={16}
            mb={8}
        >
            <Stack
                spacing={4}
                w={{ base: "60%", md: "60%" }}
                align={["center", "center", "flex-start", "flex-start"]}
            >
                <Heading
                    as="h1"
                    size="xl"
                    fontWeight="bold"
                    color="primary.900"
                    textAlign={["center", "center", "left", "left"]}
                >
                    {title}
                </Heading>

                <Heading
                    as="h2"
                    size="md"
                    color="primary.900"
                    opacity="0.9"
                    fontWeight="normal"
                    lineHeight={1.5}
                    textAlign={["center", "center", "left", "left"]}
                >
                    Department composition of employed crew with combined rating score.
                </Heading>
            </Stack>

            <Box w="200px" mb={{ base: 2, md: 2 }} color="primary.400">
                <Image src="/img/tyapp_img-career.svg" size="50%" rounded="1rem" />
            </Box>

        </Flex>
        <SimpleGrid px={8} columns={{base: 3, md: 5 }} spacingX={{base: 4, md: 4 }} spacingY={{base: 4, md: 4 }}>
            {departments.map( department =>
                <CareerCard
                    dept={capitalizeFirstLetter(department)}
                    purpose={jobs.department[department].purpose}
                />
            )}
        </SimpleGrid>
        <Text
            fontSize="s"
            mt={8}
            textAlign="center"
            color="primary.900"
            opacity="0.6"
        >
            Work rating = collection * class * title.
        </Text>
    </LandingLayout>
    );
}