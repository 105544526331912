import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Hub from "./pages/Hub";
import Crew from "./pages/Crew";
import MyCrew from "./pages/crew/MyCrew";
import Career from "./pages/Career";
import Rarity from "./pages/Rarity";
import Lottery from "./pages/Lottery";

export default function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <Hub />
        </Route>

        <Route exact path="/crew">
          <Crew />
        </Route>

        <Route path="/crew/mycrew">
          <MyCrew />
        </Route>

        <Route path="/career">
          <Career />
        </Route>

        <Route path="/rarity">
          <Rarity />
        </Route>

        <Route path="/lottery">
          <Lottery />
        </Route>
      </Switch>
    </Router>
  );
}