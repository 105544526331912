import React from "react";
import ReactDOM from "react-dom";
import { QueryClientProvider, QueryClient } from 'react-query';
import { ChakraProvider,ColorModeScript } from "@chakra-ui/react";
import { DAppProvider } from "@usedapp/core";

import App from "./App";
import customTheme from "./utils/theme";
import "@fontsource/abel/400.css";
import "@fontsource/jura/400.css";
import "./styles.css"

const queryClient = new QueryClient();

const rootElement = document.getElementById("root");
ReactDOM.render(
    <React.StrictMode>
        <QueryClientProvider client={queryClient}>
            <DAppProvider config={{}}>
                <ChakraProvider theme={customTheme}>
                    <ColorModeScript initialColorMode={customTheme.initialColorMode} />
                    <App />
                </ChakraProvider>
            </DAppProvider>
        </QueryClientProvider>
    </React.StrictMode>,
    rootElement
);