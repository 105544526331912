import { ethers } from "ethers";
import { useContractCall } from "@usedapp/core";
import asteroidContractAbi from "../contracts/asteroidContractABI.json";
import { asteroidContractAddress } from "../contracts/asteroidContractAddress";

const asteroidContractInterface = new ethers.utils.Interface(asteroidContractAbi);

export function GetAsteroidOwned(address) {
    const [balance] = useContractCall({
        abi: asteroidContractInterface,
        address: asteroidContractAddress,
        method: "balanceOf",
        args: [address],
    }) ?? [];

    if (balance) {
        return balance.toNumber();
    }

    return '?';
}