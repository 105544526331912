import React from "react";

import Hero from "../components/sections/Hero";
import LandingLayout from "../components/layouts/LandingLayout";

export default function Crew() {
  return (
    <LandingLayout>
      <Hero
        title="Tyrell-Yutani Industrial HUB"
        subtitle="Browse our range of services related to the blockchain game INFLUENCE"
        image="/img/tyapp_img-crew.svg"
        ctaText="My Crew"
        ctaLink="/crew/mycrew"
      />
    </LandingLayout>
  );
}