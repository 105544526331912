import React from "react";
import PropTypes from "prop-types";
import {
    Box,
    Image,
    Heading,
    Text
  } from "@chakra-ui/react";

import RatingStars from "../ui/RatingStars";

function zeroPad(nr,base){
  var  len = (String(base).length - String(nr).length)+1;
  return len > 0? new Array(len).join('0')+nr : nr;
}

export default function CrewCard({
  name,
  image,
  rating,
  starsLook,
  starsWork,
  ...rest
}) {
  return (
      <Box pos="relative" w={{ base: "127px", md: "155px" }} mb={{ base: 0, md: 0 }} color="primary.200">
        <Image pos="absolute" top="0" src={image} rounded="md" shadow="2xl"/>
        {RatingStars(starsLook,starsWork)}
        <Heading
          as="h1"
          width={{ base: "90px", md: "110px"}}
          size="xs"
          fontSize="11px"
          fontFamily="jura"
          fontWeight="bold"
          color="primary.900"
          textAlign={["right", "right", "right", "right"]}
          pos="absolute" top={{ base: "4px", md: "6px"}} right={{ base: "9px", md: "10px"}}
          isTruncated
        >
          {name}
        </Heading>
        <Heading
          as="h2"
          size="sm"
          color="primary.900"
          fontFamily="jura"
          fontSize={{ base: "14px", md: "17px"}}
          fontWeight="bold"
          lineHeight={1.5}
          textAlign={["center", "center", "center", "center"]}
          pos="absolute" bottom={{ base: "-157px", md: "-192px"}}  left={{ base: "16px", md: "20px"}}
        >
          {zeroPad(rating,10)}
        </Heading>
        <Text
          fontSize="9px"
          mt={2}
          textAlign="center"
          color="primary.800"
          fontFamily="jura"
          opacity="0.8"
          pos="absolute" bottom={{ base: "-133px", md: "-165px"}}  left={{ base: "7px", md: "7px"}}
        >
          {starsLook}
        </Text>
        <Text
          fontSize="9px"
          mt={2}
          textAlign="center"
          color="primary.800"
          fontFamily="jura"
          opacity="0.8"
          pos="absolute" bottom={{ base: "-166px", md: "-204px"}}  left={{ base: "40px", md: "46px"}}
        >
          {starsWork}
        </Text>
      </Box>
  );
}

CrewCard.propTypes = {
  name: PropTypes.string,
  image: PropTypes.string,
  rating: PropTypes.number,
  starsLook: PropTypes.number,
  starsWork: PropTypes.number
};

CrewCard.defaultProps = {
  name: "Crew Member #",
  image: '',
  rating: 0,
  starsLook: 0,
  starsWork: 0
};