import React from "react";
import PropTypes from "prop-types";
import {
    Box,
    Heading,
    Text
  } from "@chakra-ui/react";

export default function CareerCard({
    dept,
    purpose,
    rating,
    starsLook,
    starsWork,
    ...rest
}) {
  return (
    <Box
        pos="relative"
        minW={{ base: "100px", md: "140px"}}
        color="primary.800"
        borderWidth="2px" borderRadius="lg" borderColor="primary.400"
        
    >
        <Box
            minWidth={{ base: "100px", md: "140px"}}
            minHeight="60px"
            bgColor="primary.400"
            borderWidth="0px 0px 1px 0px" borderRadius="lg" borderColor="primary.400"
        >
            <Heading
                as="h1"
                fontSize="15px"
                fontFamily="jura"
                fontWeight="bold"
                color="primary.900"
                textAlign="center"
                py={1}
                isTruncated
            >
                {dept}
            </Heading>
            <Text
                fontSize="10px"
                mt={0}
                px={3}
                py={1}
                textAlign="center"
                color="primary.900"
                fontFamily="jura"
            >
                {purpose}
            </Text>
        </Box>
        <Box px={{ base: 4, md: 8}}>
            <Box>
                <Text
                    fontSize="10px"
                    py={1}
                    textAlign="left"
                    color="primary.700"
                    fontFamily="jura"
                >
                    Tier 1 - 
                </Text>
            </Box>
            <Box>
                <Text
                    fontSize="10px"
                    py={1}
                    textAlign="left"
                    color="primary.700"
                    fontFamily="jura"
                >
                    Tier 2 - 
                </Text>
            </Box>
            <Box>
                <Text
                    fontSize="10px"
                    py={1}
                    textAlign="left"
                    color="primary.700"
                    fontFamily="jura"
                >
                    Tier 3 - 
                </Text>
            </Box>
            <Box>
                <Text
                    fontSize="10px"
                    py={1}
                    textAlign="left"
                    color="primary.700"
                    fontFamily="jura"
                >
                    Tier 4 - 
                </Text>
            </Box>
        </Box>
    </Box>
  );
}

CareerCard.propTypes = {
    dept: PropTypes.string,
    purpose: PropTypes.string
};

CareerCard.defaultProps = {
    dept: "Department",
    purpose: 'Purpose goes here'
};