import React from "react";

import Hero from "../components/sections/Hero";
import LandingLayout from "../components/layouts/LandingLayout";

export default function Lottery() {
  return (
    <LandingLayout>
      <Hero
        title="Associate Lottery"
        subtitle="A test contract running on RINKEBY test network. Open to all T-Y associates and requires a minimum of 0.01 ETH (Rinkeby network) to enter. "
        image="/img/tyapp_img-lottery.svg"
        ctaText="Coming Soon!"
        ctaLink="/lottery"
        ctaSubText="*RINKEBY test network needs to be selected in your wallet. Tyrell-Yutani Corporation hopes you gamble responsibly."
      />
      Lottery Sign Up Here.
    </LandingLayout>
  );
}