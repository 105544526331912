import React from "react";
import { Link } from "react-router-dom";
import "../styles.css"
import {
  Box,
  Button,
  Flex,
  Image,
  Heading,
  Stack,
  Text,
  UnorderedList,
  ListItem
} from "@chakra-ui/react";

import LandingLayout from "../components/layouts/LandingLayout";

const title = "Tyrell-Yutani Industrial HUB";

export default function Hub() {
  return (
    <LandingLayout>
        <Flex
            align="center"
            justify={{ base: "center", md: "space-around", xl: "space-between" }}
            direction={{ base: "column-reverse", md: "row" }}
            wrap="wrap"
            minH="70vh"
            mx={16}
            mb={16}
        >
            <Stack
                spacing={4}
                w={{ base: "100%", md: "50%" }}
                align={["center", "center", "flex-start", "flex-start"]}
            >
                <Heading
                    as="h1"
                    w="100%"
                    size="xl"
                    fontWeight="bold"
                    color="primary.900"
                    textAlign={["center", "center", "center", "center"]}
                >
                    {title}
                </Heading>

                <Heading
                    as="h2"
                    w="100%"
                    size="md"
                    color="primary.900"
                    opacity="0.9"
                    fontWeight="normal"
                    lineHeight={1.5}
                    textAlign={["center", "center", "center", "center"]}
                >
                    Browse our range of services related to the blockchain game INFLUENCE.
                </Heading>

                <Box p="4" minW="100%" borderWidth="1px" borderRadius="lg">
                    <Link to="/crew">
                        <Button
                            width="100%"
                            colorScheme="orange"
                            borderRadius="8px"
                            py="4"
                            px="4"
                            lineHeight="1"
                            size="md"
                            textAlign="left"
                        >
                            Crew Analysis
                        </Button>
                    </Link>
                    <UnorderedList
                        fontSize="md"
                        px={6}
                        textAlign="left"
                        color="primary.900"
                    >
                        <ListItem>Modified Voight-Kampff function analysis of crew members.</ListItem>
                        <ListItem>Rates aesthetic and employment traits.</ListItem>
                    </UnorderedList>
                </Box>

                <Box p="4" minW="100%" borderWidth="1px" borderRadius="lg">
                    <Link to="/career">
                        <Button
                            width="100%"
                            colorScheme="facebook"
                            borderRadius="8px"
                            py="4"
                            px="4"
                            lineHeight="1"
                            size="md"
                            textAlign="left"
                        >
                            Career Composition - Coming Soon!
                        </Button>
                    </Link>
                    <UnorderedList
                        fontSize="md"
                        px={6}
                        textAlign="left"
                        color="primary.900"
                    >
                        <ListItem>Groupings by department ordered by work rating.</ListItem>
                        <ListItem>Visually organise and plan your crew members.</ListItem>
                    </UnorderedList>
                </Box>

                <Box p="4" minW="100%" borderWidth="1px" borderRadius="lg">
                    <Link to="/rarity">
                        <Button
                            width="100%"
                            colorScheme="facebook"
                            borderRadius="8px"
                            py="4"
                            px="4"
                            lineHeight="1"
                            size="md"
                            textAlign="left"
                        >
                            Rarity Comparison - Coming Soon!
                        </Button>
                    </Link>
                    <UnorderedList
                        fontSize="md"
                        px={6}
                        textAlign="left"
                        color="primary.900"
                    >
                        <ListItem>Filterable global crew list.</ListItem>
                        <ListItem>Headhunt specific crew members and initiate negotiations.</ListItem>
                    </UnorderedList>
                </Box>

                <Text
                    fontSize="s"
                    w="100%"
                    mt={2}
                    textAlign="center"
                    color="primary.900"
                    opacity="0.8"
                >
                    *Metamask / WalletConnect account connection required
                </Text>

            </Stack>

            <Box pos="relative" w={{ base: "80%", sm: "60%", md: "50%" }} mb={{ base: 12, md: 0 }} color="primary.400">
                <Image src="/img/tyapp_img-hub.svg" size="100%" rounded="1rem" style={{animation: "infinite 50s linear App-logo-spin"}}/>
            </Box>

        </Flex>
    </LandingLayout>
    );
}