import React from "react"
import { Box } from "@chakra-ui/react";
 
export default function RatingStars( lookRating, workRating ) {

    const fillOff = "#09181D";
    const fillOnLook = "#FF7A00";
    const fillOnWork = "#3D9AB8";

  return (
    <Box
        as="svg"
        role="img"
        pos="absolute"
        top="0"
        rounded="1rem"
        shadow="2xl"
        //   width="900px"
        //   height="1200px"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 900 1200"
        >
        <g filter="url(#filter0_d)">
            <circle cx="174.49" cy="1043.88" r="107.143" fill="#08171C"/>
            <circle cx="174.49" cy="1043.88" r="99.4898" stroke="#225164" strokeWidth="15.3061"/>
        </g>

        {/* Look Stars */}
        <g filter="url(#filter3_d)">
            <path d="M288.487 978.061L275.093 992.937L285.102 1010.27L266.816 1002.13L253.422 1017.01L255.514 997.099L237.227 988.957L256.807 984.795L258.899 964.888L268.908 982.223L288.487 978.061Z" fill={lookRating >= 80 ? fillOnLook : fillOff} stroke="#7E4026" strokeWidth="3.06122"/>
            <path d="M240.306 929.88L236.144 949.46L253.48 959.469L233.572 961.561L229.411 981.14L221.269 962.854L201.362 964.946L216.237 951.552L208.095 933.266L225.431 943.274L240.306 929.88Z" fill={lookRating >= 60 ? fillOnLook : fillOff} stroke="#7E4026" strokeWidth="3.06122"/>
            <path d="M174.489 912.245L180.675 931.282L200.692 931.282L184.498 943.048L190.683 962.085L174.489 950.32L158.295 962.085L164.481 943.048L148.287 931.282L168.304 931.282L174.489 912.245Z" fill={lookRating >= 40 ? fillOnLook : fillOff} stroke="#7E4026" strokeWidth="3.06122"/>
            <path d="M108.673 929.88L123.548 943.274L140.883 933.266L132.742 951.552L147.617 964.946L127.71 962.854L119.568 981.14L115.407 961.561L95.4992 959.469L112.834 949.46L108.673 929.88Z" fill={lookRating >= 20 ? fillOnLook : fillOff} stroke="#7E4026" strokeWidth="3.06122"/>
            <path d="M60.4926 978.061L80.0721 982.223L90.0806 964.888L92.173 984.795L111.753 988.957L93.4661 997.099L95.5584 1017.01L82.1645 1002.13L63.878 1010.27L73.8865 992.937L60.4926 978.061Z" fill={lookRating > 0 ? fillOnLook : fillOff} stroke="#7E4026" strokeWidth="3.06122"/>
        </g>

        {/* Work Stars */}
        <g filter="url(#filter2_d)">
            <path d="M288.487 1109.69L268.907 1105.53L258.899 1122.87L256.807 1102.96L237.227 1098.8L255.513 1090.66L253.421 1070.75L266.815 1085.62L285.101 1077.48L275.093 1094.82L288.487 1109.69Z" fill={workRating >= 80 ? fillOnWork : fillOff} stroke="#28434B" strokeWidth="3.06122"/>
            <path d="M240.307 1157.87L225.431 1144.48L208.096 1154.49L216.238 1136.2L201.362 1122.81L221.27 1124.9L229.411 1106.61L233.573 1126.19L253.48 1128.29L236.145 1138.3L240.307 1157.87Z" fill={workRating >= 60 ? fillOnWork : fillOff} stroke="#28434B" strokeWidth="3.06122"/>
            <path d="M174.49 1175.51L168.305 1156.47L148.288 1156.47L164.482 1144.71L158.296 1125.67L174.49 1137.44L190.684 1125.67L184.499 1144.71L200.693 1156.47L180.676 1156.47L174.49 1175.51Z" fill={workRating >= 40 ? fillOnWork : fillOff} stroke="#28434B" strokeWidth="3.06122"/>
            <path d="M108.673 1157.87L112.835 1138.3L95.4999 1128.29L115.407 1126.19L119.569 1106.61L127.711 1124.9L147.618 1122.81L132.742 1136.2L140.884 1154.49L123.549 1144.48L108.673 1157.87Z" fill={workRating >= 20 ? fillOnWork : fillOff} stroke="#28434B" strokeWidth="3.06122"/>
            <path d="M60.4921 1109.69L73.886 1094.82L63.8776 1077.48L82.164 1085.62L95.558 1070.75L93.4656 1090.66L111.752 1098.8L92.1725 1102.96L90.0801 1122.87L80.0716 1105.53L60.4921 1109.69Z" fill={workRating > 0 ? fillOnWork : fillOff} stroke="#28434B" strokeWidth="3.06122"/>
        </g>

        <defs>
            <filter id="filter0_d" x="55.1021" y="936.735" width="238.776" height="238.776" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset dy="12.2449"/>
                <feGaussianBlur stdDeviation="6.12245"/>
                <feComposite in2="hardAlpha" operator="out"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"/>
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
            </filter>
            <filter id="filter2_d" x="34.6234" y="1056" width="279.732" height="136.71" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset dy="6.12245"/>
                <feGaussianBlur stdDeviation="3.06122"/>
                <feComposite in2="hardAlpha" operator="out"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"/>
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
            </filter>
            <filter id="filter3_d" x="34.6239" y="907.292" width="279.732" height="136.71" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset dy="6.12245"/>
                <feGaussianBlur stdDeviation="3.06122"/>
                <feComposite in2="hardAlpha" operator="out"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"/>
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
            </filter>
        </defs>
    {/* </svg> */}
    </Box>
  )
}