import {
    Box,
    Button,
    Flex,
    Link,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Text,
    useDisclosure
} from "@chakra-ui/react";
import { ExternalLinkIcon, CopyIcon } from "@chakra-ui/icons";
import { useEthers, useEtherBalance, shortenAddress, getChainName, getExplorerAddressLink } from "@usedapp/core";
import { formatEther } from "@ethersproject/units";
import Identicon from "./Identicon";

//import {GetCrewSupply} from "../../hooks/crewSupply";
import {GetCrewOwned} from "../../hooks/crewOwned";
//import {GetAsteroidSupply} from "../../hooks/asteroidSupply";
import {GetAsteroidOwned} from "../../hooks/asteroidOwned";


export default function ConnectButton() {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { activateBrowserWallet, account, deactivate, chainId } = useEthers();

    const etherBalance = useEtherBalance(account);

    //const crewSupply = GetCrewSupply();
    const crewOwned = GetCrewOwned(account);
    //const asteroidSupply = GetAsteroidSupply();
    const asteroidOwned = GetAsteroidOwned(account);

    // console.log("Crew Supply: ",crewSupply);
    // console.log("Crew Owned: ",crewOwned);
    // console.log("Roid Supply: ",asteroidSupply);
    // console.log("Roid Owned: ",asteroidOwned);

    function handleConnectWallet() {
        activateBrowserWallet();
    }
  
    function handleDeactivateAccount() {
        deactivate();
        onClose();
    }

    function copyToClipboard() {
        navigator.clipboard.writeText(account);
    };

    return account ? (
        <Box
            mt={6}
            display="column"
            alignItems="center"
        >
            <Box
                display="flex"
                alignItems="center"
                background="primary.300"
                borderRadius="10px"
                py="0"
            >
                <Box pl="2" pr="1"  minWidth="40px">
                    <Text color="primary.900" fontSize="12px">
                        {etherBalance && parseFloat(formatEther(etherBalance)).toFixed(3)} E
                    </Text>
                </Box>

                <Button
                    onClick={ onOpen }
                    bg="primary.600"
                    border="3px solid transparent"
                    _hover={{
                        border: "1px",
                        borderStyle: "solid",
                        borderColor: "blue.400",
                        backgroundColor: "gray.700",
                    }}
                    borderRadius="9px"
                    m={0.5}
                    px={3}
                    height="30px"
                >
                    <Text color="white" fontSize="md" fontWeight="medium" mr="2">
                        {shortenAddress(account)}
                    </Text>
                    <Identicon />
                </Button>

                <Box pr="2" pl="1" minWidth="40px">
                    <Text textAlign="right" color="primary.900" fontSize="12px" lineHeight="1">
                        {asteroidOwned} roids
                    </Text>
                    <Text textAlign="right" color="primary.900" fontSize="12px" lineHeight="1">
                        {crewOwned} crew
                    </Text>
                </Box>
            </Box>

            <Text textAlign="center" color="primary.900" fontSize="sm" fontWeight="medium" mr="2">
                {getChainName(chainId)} Network
            </Text>

            {/*---------------------Modal------------------------------*/}

            <Modal isOpen={isOpen} onClose={onClose} isCentered size="md">
                <ModalOverlay />
                <ModalContent
                    background="gray.900"
                    border="1px"
                    borderStyle="solid"
                    borderColor="gray.700"
                    borderRadius="3xl"
                >
                    <ModalHeader color="white" px={4} fontSize="lg" fontWeight="medium">
                        Account
                    </ModalHeader>

                    <ModalCloseButton
                        color="white"
                        fontSize="sm"
                        _hover={{
                            color: "whiteAlpha.700",
                        }}
                    />
                    <ModalBody pt={0} px={4}>
                        <Box
                            borderRadius="3xl"
                            border="1px"
                            borderStyle="solid"
                            borderColor="gray.600"
                            px={5}
                            pt={4}
                            pb={2}
                            mb={3}
                        >
                            <Flex justifyContent="space-between" alignItems="center" mb={3}>
                                <Text color="gray.400" fontSize="sm">
                                    Connected with MetaMask
                                </Text>
                                <Button
                                    variant="outline"
                                    size="sm"
                                    borderColor="blue.800"
                                    borderRadius="3xl"
                                    color="blue.500"
                                    fontSize="13px"
                                    fontWeight="normal"
                                    px={2}
                                    height="26px"
                                    _hover={{
                                    background: "none",
                                    borderColor: "blue.300",
                                    textDecoration: "underline",
                                    }}
                                    onClick={handleDeactivateAccount}
                                >
                                    Change
                                </Button>
                            </Flex>
                            <Flex alignItems="center" mt={2} mb={4} lineHeight={1}>
                                <Identicon />
                                <Text
                                    color="white"
                                    fontSize="xl"
                                    fontWeight="semibold"
                                    ml="2"
                                    lineHeight="1.1"
                                >
                                    {account &&
                                    `${account.slice(0, 6)}...${account.slice(
                                    account.length - 4,
                                    account.length
                                    )}`}
                                </Text>
                            </Flex>
                            <Flex alignContent="center" m={3}>
                                <Button
                                    variant="link"
                                    color="gray.400"
                                    fontWeight="normal"
                                    fontSize="sm"
                                    _hover={{
                                    textDecoration: "none",
                                    color: "whiteAlpha.800",
                                    }}
                                    onClick={() => copyToClipboard()}
                                >
                                    <CopyIcon mr={1} />
                                    Copy Address
                                </Button>
                            <Link
                            fontSize="sm"
                            display="flex"
                            alignItems="center"
                            href={getExplorerAddressLink(account, chainId)}
                            isExternal
                            color="gray.400"
                            ml={6}
                            _hover={{
                            color: "whiteAlpha.800",
                            textDecoration: "underline",
                            }}
                            >
                            <ExternalLinkIcon mr={1} />
                            View on Explorer
                            </Link>
                            </Flex>
                        </Box>
                    </ModalBody>

                    <ModalFooter
                        justifyContent="end"
                        background="gray.700"
                        borderBottomLeftRadius="3xl"
                        borderBottomRightRadius="3xl"
                        p={6}
                    >
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Box>
        ) : (
        <Button
            onClick={handleConnectWallet}
            bg="blue.800"
            color="blue.300"
            fontSize="lg"
            fontWeight="medium"
            borderRadius="xl"
            border="1px solid transparent"
            _hover={{
                borderColor: "blue.700",
                color: "blue.400",
            }}
            _active={{
                backgroundColor: "blue.800",
                borderColor: "blue.700",
            }}
        >
            Connect
        </Button>
    );
}

